












































































































import {
  ref,
  toRefs,
  reactive,
  defineComponent,
} from 'vue-demi'

import { useToast } from 'vue-toastification/composition'

import { VForm } from '@/types/vuetify'
import { ComposedCropEvent } from '@/types/cropper'

import { useI18n } from '@/i18n'

import { usersProvider } from '@/providers/users'

import { rules, unValidateForm, validateForm } from '@/utils/validators'

import ImageCropper from '@/components/widgets/ImageCropper.vue'

interface Props extends Record<string, any> {
  readonly userId: number
  readonly loading: boolean
}

export default defineComponent<Props>({
  components: {
    ImageCropper,
  },

  props: {
    userId: {
      type: Number,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { userId } = toRefs(props)

    const i18n = useI18n()
    const toast = useToast()

    const formRef = ref<VForm>()

    const errors = ref({})

    const state = reactive({
      modal: false,
      loading: false,
    })

    const avatar = reactive<{
      original: File | null
      cropped: string
      file: Blob | null
    }>({
      original: null,
      cropped: '',
      file: null,
    })

    async function onSubmit() {
      const isValid = validateForm(formRef.value)
      if (!isValid || !avatar.file || !avatar.original) { return }

      try {
        state.loading = true

        const form = new FormData()
        form.append('user[avatar]', avatar.file, avatar.original?.name)

        await usersProvider.update(
          { id: userId.value },
          form,
        )

        onSuccessSubmit()
      } catch (error) {
        errors.value = error
        onFailSubmit()
      } finally {
        state.loading = false
      }
    }

    function onSuccessSubmit() {
      toast.success(i18n.t('ui.success.user_updated'))
      state.modal = false

      resetDialog()

      emit('success')
    }

    function onFailSubmit() {
      toast.error(i18n.t(errors.value.toString()))

      emit('fail')
    }

    function resetState() {
      unValidateForm(formRef.value)
    }

    function resetDialog() {
      state.modal = false
      state.loading = false
      avatar.original = null
      avatar.cropped = ''
      avatar.file = null
      resetState()
    }

    function onAvatarCropped({ type, cropped, blob }: ComposedCropEvent) {
      console.log('onAvatarCropped', { type })
      avatar.cropped = cropped
      avatar.file = blob
    }

    return {
      state,
      formRef,

      avatar,

      errors,

      rules,
      onSubmit,
      onAvatarCropped,

      resetDialog,
    }
  },
})

