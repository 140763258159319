




















































import {
  ref,
  computed,
  defineComponent,
  watch,
} from 'vue-demi'

// @ts-ignore
import StarRating from 'vue-star-rating'

import { useStore } from '@/store'

import ChangeStatusDialog from '@/components/dialogs/ChangeStatusDialog.vue'
import { ACTIONS, GETTERS, MODULES } from '@/store/types'
import { Payload } from '@/types/store'

export default defineComponent({
  components: {
    StarRating,
    ChangeStatusDialog,
  },

  setup() {
    const store = useStore()

    const rating = ref(0)

    const company = computed(() => store.state.Profile.company)

    const reviewsArrayLength = computed(() => store.getters[`${MODULES.Reviews}/${GETTERS.Reviews.getReviewsListLength}`](company.value?.id as any))

    async function getReviewsList(id: string) {
      return store.dispatch<Payload<any>>({
        type: `${MODULES.Reviews}/${ACTIONS.Reviews.fetchReviewsList}`,
        id,
      })
    }

    watch(
      company,
      async () => {
        if (company.value) {
          await getReviewsList(company.value.id as any)
        }
      },
      { deep: true, immediate: true },
    )

    return {
      rating,
      reviewsArrayLength,
      company,
    }
  },
})

