


































import { computed, defineComponent } from 'vue-demi'

// @ts-ignore
import { FadeTransition } from 'vue2-transitions'

import { useStore } from '@/store'
import { MODULES, ACTIONS } from '@/store/types'

import PreviewModal from '@/components/widgets/PreviewModal.vue'
import DocumentsAddDialog from '@/components/dialogs/DocumentsAddDialog.vue'

export default defineComponent({
  components: {
    PreviewModal,
    FadeTransition,
    DocumentsAddDialog,
  },

  setup() {
    const store = useStore()
    const company = computed(() => store.state.Profile.company)

    function onSuccess() {
      store.dispatch(`${MODULES.Profile}/${ACTIONS.Profile.loadMyCompany}`)
    }

    return { company, onSuccess }
  },
})

