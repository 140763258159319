












































































































import {
  ref,
  toRefs,
  reactive,
  defineComponent,
} from 'vue-demi'

import { useToast } from 'vue-toastification/composition'

import { VForm } from '@/types/vuetify'
import { ComposedCropEvent } from '@/types/cropper'

import { useI18n } from '@/i18n'

import { companiesProvider } from '@/providers/companies'

import { rules, unValidateForm, validateForm } from '@/utils/validators'

import ImageCropper from '@/components/widgets/ImageCropper.vue'

interface Props extends Record<string, any> {
  readonly companyId: number
  readonly loading: boolean
}

export default defineComponent<Props>({
  components: {
    ImageCropper,
  },

  props: {
    companyId: {
      type: Number,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { companyId } = toRefs(props)

    const i18n = useI18n()
    const toast = useToast()

    const formRef = ref<VForm>()

    const errors = ref({})

    const state = reactive({
      modal: false,
      loading: false,
    })

    const logo = reactive<{
      original: File | null
      cropped: string
      file: Blob | null
    }>({
      original: null,
      cropped: '',
      file: null,
    })

    async function onSubmit() {
      const isValid = validateForm(formRef.value)
      if (!isValid || !logo.file || !logo.original) { return }

      try {
        state.loading = true

        const form = new FormData()
        form.append('company[logo]', logo.file, logo.original?.name)

        await companiesProvider.update(
          { id: companyId.value },
          form,
        )

        onSuccessSubmit()
      } catch (error) {
        errors.value = error
        onFailSubmit()
      } finally {
        state.loading = false
      }
    }

    function onSuccessSubmit() {
      toast.success(i18n.t('ui.success.user_updated'))
      state.modal = false
      resetDialog()
      emit('success')
    }

    function onFailSubmit() {
      toast.error(i18n.t(errors.value.toString()))
      emit('fail')
    }

    function resetFormState() {
      unValidateForm(formRef.value)
    }

    function resetDialog() {
      state.modal = false
      state.loading = false
      logo.original = null
      logo.cropped = ''
      logo.file = null
      resetFormState()
    }

    function onLogoCropped({ type, cropped, blob }: ComposedCropEvent) {
      console.log('onLogoCropped', { type })
      logo.cropped = cropped
      logo.file = blob
    }

    return {
      state,
      formRef,

      logo,

      errors,

      rules,
      onSubmit,
      onLogoCropped,

      resetDialog,
    }
  },
})

