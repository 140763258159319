


























































import {
  ref,
  watch,
  computed,
  defineComponent,
} from 'vue-demi'
import { useToast } from 'vue-toastification/composition'

import { User } from '@/types/api'
import { VDataTableHeader } from '@/types/vuetify'

import { useStore } from '@/store'

import { companiesProvider } from '@/providers/companies'

import WorkTypes from '@/components/WorkTypes.vue'
import UserUpdateEditDialog from '@/components/dialogs/UserUpdateEditDialog.vue'
import CompanyEmployerAddDialog from '@/components/dialogs/CompanyEmployerAddDialog.vue'

export default defineComponent({
  components: {
    WorkTypes,
    UserUpdateEditDialog,
    CompanyEmployerAddDialog,
  },

  setup() {
    const toast = useToast()
    const store = useStore()

    const companyId = computed(() => store.state.Profile.company?.id)

    const headers: VDataTableHeader[] = [
      {
        text: 'ФИО',
        align: 'start',
        sortable: false,
        value: 'fio',
      },
      { text: 'Email', value: 'email' },
      { text: 'Телефон', value: 'phone_number' },
      { text: '', value: 'actions', width: 48 },
    ]

    const employersList = ref<User[]>([])

    function editItem() {
      console.log('editItem')
    }

    async function loadEmployees() {
      if (companyId.value == null) { return }

      try {
        const { results } = await companiesProvider.getCompanyEmployersList({ id: companyId.value })
        employersList.value = results
      } catch (error) {
        toast.error(`loadEmployees: ${error.error}`)
      }
    }

    watch(
      () => companyId.value,
      loadEmployees,
      { immediate: true },
    )

    return {
      headers,
      companyId,
      employersList,
      loadEmployees,

      editItem,
    }
  },
})

