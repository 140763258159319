













import {
  watch,
  toRefs,
  reactive,
  defineComponent,
} from 'vue-demi'

import { CropResult } from '@/types/cropper'

// @ts-ignore
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

import { createPreviewUri } from '@/utils/files'

interface Props extends Record<string, any> {
  readonly source: File
}

const stencilSettings = {
  'aspect-ratio': 1,
  'lines': {
    north: true,
    west: false,
    south: true,
    east: false,
  },
  'handlers': {
    eastNorth: true,
    westNorth: true,
    westSouth: true,
    eastSouth: true,
    north: false,
    west: false,
    south: false,
    east: false,
  },
}

function onReady() {
  console.log('onReady')
}

function onError() {
  console.log('onError')
}

export default defineComponent<Props>({
  components: {
    Cropper,
  },

  props: {
    source: {
      required: true,
    },
  },

  setup(props, { emit }) {
    const { source } = toRefs(props)

    const state = reactive({
      original: '',
      cropped: '',
      type: '',
    })

    async function processInputImage(file: File) {
      if (!file || typeof file !== 'object') {
        state.original = ''
        state.cropped = ''
        state.type = ''

        emit('change', { blob: null, ...state })
        return
      }

      const { src, type } = await createPreviewUri(file)

      if (process.env.NODE_ENV === 'development') {
        console.log('%c Image cropped ', 'color:white;background-color:blue', { name: file.name, type: file.type })
      }

      state.original = src
      state.type = type
    }

    function onChange({ canvas }: CropResult) {
      state.cropped = canvas.toDataURL(state.type)
      canvas.toBlob(blob => {
        if (!blob) { return }
        emit('change', { blob, ...state })
      }, state.type)
    }

    watch(
      () => source.value,
      processInputImage,
      { immediate: true, deep: true },
    )

    return {
      state,

      stencilSettings,

      onChange,
      onReady,
      onError,
    }
  },
})

