








































import {
  defineComponent,
} from 'vue-demi'

// @ts-ignore
import { FadeTransition } from 'vue2-transitions'

export default defineComponent({
  components: {
    FadeTransition,
  },
})

