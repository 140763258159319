










































































































































import {
  ref,
  watch,
  toRefs,
  reactive,
  computed,
  defineComponent,
} from 'vue-demi'
import { useToast } from 'vue-toastification/composition'
import { createMachine } from '@xstate/fsm'

import { VForm } from '@/types/vuetify'

// Localization
import { useI18n } from '@/i18n'

import { useMachine } from '@/utils/state-machine'

import { usersRepository } from '@/repositories'

// Utils
import {
  rules,
  validateForm,
  unValidateForm,
} from '@/utils/validators'

interface Props extends Record<string, any> {
  readonly userId: number
}

const machine = createMachine({
  id: 'sign-in-machine',
  initial: 'inactive',
  states: {
    inactive: {
      on: { TOGGLE: 'active' },
    },
    active: {
      on: { TOGGLE: 'inactive' },
    },
  },
})

export default defineComponent<Props>({
  components: {},

  props: {
    userId: {
      type: Number,
      required: true,
    },
  },

  setup(props, { emit }) {
    const i18n = useI18n()
    const toast = useToast()

    const { userId } = toRefs(props)

    const modal = ref(false)

    /**
     * * @AlexQuidditch
     * уникальное имя для формы, т.к. их в DOM несколько
     */
    const formName = computed(() => `user-update-form-${userId.value}`)

    // ref="formRef"
    const formRef = ref<VForm>()

    const showPassword = ref(false)

    const { state: loadingState, send } = useMachine(machine)

    const form = reactive({
      user: {
        email: '',
        password: '',
        password_confirmation: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        phone_number: '',
        company_id: 0,
        avatar: null,
      },
    })

    const errors = ref({})

    const loading = computed(() => loadingState.value.matches('active'))

    async function fetchUser() {
      const result = await usersRepository.find({ id: userId.value })

      form.user.email = result.email
      form.user.first_name = result.first_name
      form.user.middle_name = result.middle_name
      form.user.last_name = result.last_name
      form.user.phone_number = result.phone_number
    }

    function onPasswordChange(value: string) {
      form.user.password = value
      form.user.password_confirmation = value
    }

    function resetForm() {
      console.log('resetForm:', formRef.value)

      unValidateForm(formRef.value)
      modal.value = false
    }

    async function onSubmit() {
      const formElement = document.forms.namedItem(formName.value)

      if (!formElement) { return }

      const isValid = validateForm(formRef.value)
      if (!isValid) { return }

      const payload = new FormData(formElement)

      if (process.env.NODE_ENV !== 'production') {
        console.groupCollapsed(formName.value)
        payload.forEach((value, key) => console.log({ key, value }))
        console.groupEnd()
      }

      try {
        send('TOGGLE')

        await usersRepository.update({ id: userId.value }, payload)

        onSuccessSubmit()
      } catch (error) {
        errors.value = error
        onFailSubmit()
      } finally {
        send('TOGGLE')
      }
    }

    function onSuccessSubmit() {
      toast.success(i18n.t('ui.success.user_updated'))
      modal.value = false

      resetForm()

      emit('success')
    }

    function onFailSubmit() {
      toast.error(i18n.t(errors.value.toString()))

      emit('fail')
    }

    watch(
      () => userId.value,
      fetchUser,
      { immediate: true },
    )

    return {
      send,
      loading,

      formRef,

      onSuccessSubmit,
      onFailSubmit,
      modal,
      onPasswordChange,

      onSubmit,
      resetForm,

      errors,
      rules,

      formName,

      showPassword,
      ...form,
    }
  },
})

