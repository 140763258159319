



















import { defineComponent } from 'vue-demi'

// @ts-ignore
import { FadeTransition } from 'vue2-transitions'

import WorksFolderAddDialog from '@/components/dialogs/WorksFolderAddDialog.vue'

export default defineComponent({
  components: {
    FadeTransition,
    WorksFolderAddDialog,
  },
})

