



































































































import {
  toRefs,
  computed,
  defineComponent,
  provide,
} from 'vue-demi'

import { useI18n } from '@/i18n'
import { useStore } from '@/store'
import { useRouter } from '@/router'

import { ACTIONS, MODULES } from '@/store/types'

import Reviews from '@/components/views/MyCompanyReviews.vue'
import Employers from '@/components/views/MyCompanyEmployers.vue'
import Overview from '@/components/views/MyCompanyOverview.vue'
import Requisites from '@/components/views/MyCompanyRequisites.vue'
import MyCompanyDocuments from '@/components/views/MyCompanyDocuments.vue'

import MyCompanyProfile from '@/components/views/MyCompanyProfile.vue'

import MyCompanyEditDialog from '@/components/dialogs/MyCompanyEditDialog.vue'

interface Props extends Record<string, any> {
  readonly section: string
}

export default defineComponent<Props>({
  components: {
    about: Overview,
    employers: Employers,
    requisites: Requisites,
    documents: MyCompanyDocuments,
    reviews: Reviews,
    MyCompanyProfile,
    MyCompanyEditDialog,
  },

  props: {
    section: {
      type: String,
      default: 'overview',
    },
  },

  setup(props) {
    const { section } = toRefs(props)

    const i18n = useI18n()
    const store = useStore()
    const router = useRouter()

    const company = computed(() => store.state.Profile.company)

    // Headers
    const items = [
      { title: i18n.t('ui.company.about'), component: 'about' },
      { title: i18n.t('ui.company.employers'), component: 'employers' },
      { title: i18n.t('ui.company.requisites'), component: 'requisites' },
      { title: i18n.t('ui.company.documents'), component: 'documents' },
      { title: i18n.t('ui.company.reviews'), component: 'reviews' },
    ]

    const tab = computed({
      get: () => {
        let index = 0

        const itemIndex = items.findIndex(item => item.component === section.value)
        if (itemIndex !== -1) { index = itemIndex }

        return index
      },

      set: index => {
        router.push({ params: { section: items[index].component } })
      },
    })

    function reloadCompany() {
      store.dispatch(`${MODULES.Profile}/${ACTIONS.Profile.loadMyCompany}`, { id: company.value?.id })
    }

    const user = computed(() => store.state.Profile.user)

    provide('user', user)

    return {
      tab,
      items,

      company,
      reloadCompany,
    }
  },
})

