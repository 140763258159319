














































































import {
  ref,
  defineComponent,
  reactive,
  toRefs,
} from 'vue-demi'
import { useToast } from 'vue-toastification/composition'

import { VForm } from '@/types/vuetify'

import { useI18n } from '@/i18n'

import { unValidateForm, validateForm } from '@/utils/validators'

import DragAndDropZone from '@/components/widgets/DragAndDropZone.vue'
import { companiesRepository } from '@/repositories/companies'

interface Props extends Record<string, any> {
  companyId: number
}

export default defineComponent<Props>({
  props: {
    companyId: {
      type: Number,
      required: true,
    },
  },

  components: {
    DragAndDropZone,
  },

  setup(props, { emit }) {
    const { companyId } = toRefs(props)

    const i18n = useI18n()
    const toast = useToast()

    const formRef = ref<VForm>()

    const errors = ref({})

    const state = reactive({
      modal: false,
      loading: false,
    })

    const formState = reactive<{ images: File[] }>({
      images: [],
    })

    async function onSubmit() {
      const isValid = validateForm(formRef.value)
      if (!isValid) { return }

      try {
        state.loading = true

        const form = new FormData()
        formState.images.forEach(image => form.append('company[documents][]', image, image.name))

        await companiesRepository.update(
          { id: companyId.value },
          form,
        )

        onSuccessSubmit()
      } catch (error) {
        errors.value = error
        onFailSubmit()
      } finally {
        state.loading = false
      }
    }

    function onSuccessSubmit() {
      toast.success(i18n.t('ui.success.company_updated'))
      state.modal = false
      resetDialog()
      emit('success')
    }

    function onFailSubmit() {
      toast.error(i18n.t(errors.value.toString()))
      emit('fail')
    }

    function resetFormState() {
      unValidateForm(formRef.value)
      formState.images = []
    }

    function resetDialog() {
      state.modal = false
      state.loading = false
      resetFormState()
    }

    return {
      state,

      formRef,
      formState,

      onSubmit,
      resetDialog,
    }
  },
})

