






































































































































import {
  ref,
  toRefs,
  reactive,
  computed,
  defineComponent,
} from 'vue-demi'
import { useToast } from 'vue-toastification/composition'
import { createMachine } from '@xstate/fsm'

import { UserCreateInput } from '@/types/api'
import { VForm } from '@/types/vuetify'

// Localization
import { useI18n } from '@/i18n'

import { useMachine } from '@/utils/state-machine'

// Utils
import {
  rules,
  validateForm,
  unValidateForm,
} from '@/utils/validators'

import { companiesProvider } from '@/providers/companies'

interface Props extends Record<string, any> {
  readonly companyId: number
}

const machine = createMachine({
  id: 'sign-in-machine',
  initial: 'inactive',
  states: {
    inactive: {
      on: { TOGGLE: 'active' },
    },
    active: {
      on: { TOGGLE: 'inactive' },
    },
  },
})

export default defineComponent<Props>({
  components: {},

  props: {
    companyId: {
      type: Number,
      required: true,
    },
  },

  setup(props, { emit }) {
    const i18n = useI18n()
    const toast = useToast()

    const { companyId } = toRefs(props)

    const modal = ref(false)

    const formRef = ref<VForm>()

    const showPassword = ref(false)

    const { state: loadingState, send } = useMachine(machine)

    const form = reactive<UserCreateInput>({
      user: {
        email: '',
        password: '',
        password_confirmation: '',
        first_name: '',
        middle_name: '',
        last_name: '',
      },
    })

    const errors = ref([])

    const loading = computed(() => loadingState.value.matches('active'))

    function onPasswordChange(value: string) {
      form.user.password = value
      form.user.password_confirmation = value
    }

    function resetForm() {
      unValidateForm(formRef.value)

      form.user.email = ''
      form.user.password = ''
      form.user.password_confirmation = ''
      form.user.first_name = ''
      form.user.middle_name = ''
      form.user.last_name = ''
    }

    async function onSubmit() {
      const isValid = validateForm(formRef.value)
      if (!isValid) { return }

      try {
        send('TOGGLE')

        await companiesProvider.createCompanyEmployer({ id: companyId.value }, form)

        onSuccessSubmit()
      } catch (error) {
        errors.value = error
        onFailSubmit()
      } finally {
        send('TOGGLE')
      }
    }

    function onSuccessSubmit() {
      toast.success(i18n.t('ui.success.user_created'))
      modal.value = false

      resetForm()

      emit('success')
    }

    function onFailSubmit() {
      const errorStr = errors.value.reduce((accum, err): string => `${accum}${i18n.t(err)} \r\n`, '')
      toast.error(errorStr)

      emit('fail')
    }

    return {
      send,
      loading,

      formRef,

      onSuccessSubmit,
      onFailSubmit,
      modal,
      onPasswordChange,

      onSubmit,
      resetForm,

      errors,
      rules,

      showPassword,
      ...form,
    }
  },
})

