











import { defineComponent } from 'vue-demi'

import ReviewCard from '@/components/ReviewCard.vue'

const reviews: Array<Record<string, any>> = [
  {
    id: 0,
    author: {
      firstName: 'Андрей',
      lastName: 'Марков',
      role: 'Заказчик',
    },
    createdAt: new Date(),
    orderId: 12,
    pros: 'Работы выполнил быстрее обговоренного срока. Но при этом качеством проведенных работа доволен. В процессе работ всегда был на связи и активно помогал в решении нестандартных проблем. Работал очень аккуратно и сам убирал за собой мусор. Цена в процессе работ не менялась, что не может не радовать.',
    cons: '',
    rating: 5,
  },
]

export default defineComponent({
  components: {
    ReviewCard,
  },

  props: {},

  setup() {
    return { reviews }
  },
})

