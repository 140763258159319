



































































































import {
  toRefs,
  computed,
  defineComponent,
} from 'vue-demi'

import { useI18n } from '@/i18n'
import { useStore } from '@/store'
import { useRouter } from '@/router'

import { MODULES, ACTIONS } from '@/store/types'

import Certification from '@/components/views/MeCertification.vue'
import MeProfile from '@/components/views/MeProfile.vue'
import Overview from '@/components/views/MeOverview.vue'
import Reviews from '@/components/views/MeReviews.vue'
import Works from '@/components/views/MeWorks.vue'

import MeProfileEditDialog from '@/components/dialogs/MeProfileEditDialog.vue'

interface Props extends Record<string, any> {
  readonly section: string
}

export default defineComponent<Props>({
  components: {
    works: Works,
    reviews: Reviews,
    overview: Overview,
    certification: Certification,
    MeProfile,
    MeProfileEditDialog,
  },

  props: {
    section: {
      type: String,
      default: 'overview',
    },
  },

  setup(props) {
    const { section } = toRefs(props)

    const i18n = useI18n()
    const store = useStore()
    const router = useRouter()

    const user = computed(() => store.state.Profile.user)

    // Tabs
    const items = [
      { title: i18n.t('ui.me.overview'), component: 'overview' },
      { title: i18n.t('ui.me.certification'), component: 'certification' },
      { title: i18n.t('ui.me.works'), component: 'works' },
      { title: i18n.t('ui.me.reviews'), component: 'reviews' },
    ]

    const tab = computed({
      get: () => {
        let index = 0

        const itemIndex = items.findIndex(item => item.component === section.value)
        if (itemIndex !== -1) { index = itemIndex }

        return index
      },

      set: index => {
        router.push({ params: { section: items[index].component } })
      },
    })

    function reloadUser() {
      store.dispatch(`${MODULES.Profile}/${ACTIONS.Profile.loadProfile}`)
    }

    return {
      tab,
      items,

      user,
      reloadUser,
    }
  },
})

