









































































































import {
  ref,
  watch,
  computed,
  reactive,
  defineComponent,
} from 'vue-demi'
import { useToast } from 'vue-toastification/composition'
import { createMachine } from '@xstate/fsm'

import { Maybe } from '@/types'

import {
  Company,
  CompanyUpdateInput,
  CompanyWhereInput,
} from '@/types/api'
import { Payload } from '@/types/store'
import { VForm } from '@/types/vuetify'

import { useI18n } from '@/i18n'

import { useStore } from '@/store'
import { ACTIONS, MODULES } from '@/store/types'

import { companiesRepository } from '@/repositories'

import { validateForm } from '@/utils/validators'
import { useMachine } from '@/utils/state-machine'

import WorkTypes from '@/components/WorkTypes.vue'

const companyKeys = [
  'title',
  'kpp',
  'inn',
  'ogrn',
]

const ceoKeys = [
  'ceo',
  'ceo_post',
  'registration_date',
  'address',
  'business_address',
]

const machine = createMachine({
  id: 'sign-in-machine',
  initial: 'inactive',
  states: {
    inactive: {
      on: { TOGGLE: 'active' },
    },
    active: {
      on: { TOGGLE: 'inactive' },
    },
  },
})

export default defineComponent({
  components: {
    WorkTypes,
  },

  setup(_, { emit }) {
    const i18n = useI18n()
    const toast = useToast()
    const store = useStore()
    const company = computed(() => store.state.Profile.company)

    const formRef = ref<VForm>()

    const isCeoEditing = ref(false)
    const isCompanyEditing = ref(false)

    const { state: loadingState, send } = useMachine(machine)

    const loading = computed(() => loadingState.value.matches('active'))

    const state = reactive<{ company: CompanyUpdateInput }>({
      company: {
        title: '',
        description: '',
        inn: '',
        kpp: '',
        ogrn: '',
        ceo: '',
        phone_number: '',
        registration_date: '',
        address: '',
        business_address: '',
        work_ids: [],
        user_ids: [],
        documents: [],
      },
    })

    const errors = ref({})

    function onSuccessSubmit() {
      toast.success(i18n.t('ui.success.company_updated'))

      if (!company.value?.id) { return }

      store.dispatch<Payload<CompanyWhereInput>>({
        type: `${MODULES.Profile}/${ACTIONS.Profile.loadMyCompany}`,
        id: company.value?.id,
      })

      emit('success')
    }

    function onFailSubmit() {
      toast.error(i18n.t(errors.value.toString()))

      emit('fail')
    }

    async function onSubmit() {
      const isValid = validateForm(formRef.value)
      if (!isValid || !company.value?.id) { return }

      try {
        send('TOGGLE')

        await companiesRepository.update({ id: company.value.id }, state.company)

        onSuccessSubmit()
      } catch (error) {
        errors.value = error
        onFailSubmit()
      } finally {
        send('TOGGLE')
      }
    }

    function preFillCompany(value: Maybe<Company>) {
      if (!value) { return }
      Object.keys(state.company).forEach(key => { state.company[key] = value[key] })
    }

    /**
     * * @AlexQuidditch
     * предзаполнение данными из хранилища для удобства редактирования
     */
    watch(
      () => company.value,
      value => preFillCompany(value),
      { immediate: true, deep: true },
    )

    return {
      companyKeys,
      ceoKeys,

      formRef,

      isCompanyEditing,
      isCeoEditing,
      loading,

      state,
      company,

      send,
      onSubmit,
      preFillCompany,
    }
  },
})

