




















































































import {
  ref,
  computed,
  defineComponent,
} from 'vue-demi'

// @ts-ignore
import StarRating from 'vue-star-rating'

import { useStore } from '@/store'

import ChangeStatusDialog from '@/components/dialogs/ChangeStatusDialog.vue'

export default defineComponent({
  components: {
    StarRating,
    ChangeStatusDialog,
  },

  setup() {
    const store = useStore()

    const rating = ref(3)

    const profile = computed(() => store.state.Profile.user)

    return { rating, profile }
  },
})

